import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import { isIndexPage } from '../../../utils';
import { graphql as gatsbyGraphql, Link } from "gatsby";
export const pageQuery = gatsbyGraphql`
  query eIDs {
    pages: allMdx(
      filter: {
        frontmatter: {
          product: { eq: "verify" }
          category: { eq: "eIDs" }
        }
      }
      sort: { fields: [frontmatter___sort], order: ASC }
    ) {
      edges {
        node {
          __typename
          id
          slug
          fileAbsolutePath
          frontmatter {
            title
            subtitle
            category
          }
        }
      }
    }
  }
`;
export const _frontmatter = {
  "product": "verify",
  "category": "eIDs",
  "sort": 0,
  "title": "eIDS supported by Criipto Verify"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <p>{`Criipto Verify supports authentication via a number of eIDs.`}</p>
    <p>{`Select any individual eID from the list below to see JWT/token details, how to find/create test users and how to order a specific eID for production.`}</p>
    <ul>
  {props.data.pages.edges.map(edge => edge.node).filter(node => !isIndexPage(node)).map(node => <li key={node.id}>
      <Link to={`/${node.slug}/`} mdxType="Link">{node.frontmatter.title}</Link><br />
      {node.frontmatter.subtitle}
    </li>)}
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      